import {
    AppLayout,
    Box,
    BreadcrumbGroup,
    Header,
    HelpPanel,
    SpaceBetween,
    FormField,
    Input,
    Button,
    Container
} from "@cloudscape-design/components";
import NotificationFlashbar from "./components/NotificationFlashbar";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import GitHubLinks from "./components/GitHubLinks";
import Navigation from "./components/Navigation";
import AWS from "aws-sdk";


const UserRequestForm = () => {
  const [inputError, setInputError] = useState({});
  const [value, setValue] = useState({});
  const userRequest = {};

  useEffect(() => {
    setInputError({});
    setValue({
      email: "",
      budget: "",
      time: "",
      department: "",
      reason: ""
    });
  }, []);

  const updateFormValue = (update) => {
    setValue((prev) => {
      let newValue = { ...prev, ...update };
      // validateInputs(newValue, true);
      return newValue;
    });
  };

  const submit = async () => {
    const SES = new AWS.SES({
        accessKeyId: 'AKIATKWLAXRB74O2PWWE',
        secretAccessKey: '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',
        region: 'us-east-1'
    });
    const emailParams = {
        Destination: {
            ToAddresses: ["dania.alrefai@bespinglobal.ae", "hamzeh.shaghlil@bespinglobal.ae"]
        },
        Message: {
            Body: {
                Html: {
                    Charset: "UTF-8",
                    Data: `<h4>A new AWS sandbox account request from ${value.email}</h4>
                    <p>Department: ${value.department}</p>
                    <p>Budget in USD: ${value.budget} </p>
                    <p>Required session time: ${value.time}</p>
                    <p>Reason: ${value.reason}</p>`
                },
                Text: {
                    Charset: "UTF-8",
                    Data: "New sandbox account required"
                }
            },
            Subject: {
                Charset: 'UTF-8',
                Data: 'AWS sandbox account request'
            }
        },
        Source: "noreply@bespinglobal.ae"
    };
    const emailResponse = await SES.sendEmail(emailParams).promise();
    console.log(emailResponse);
    setValue({
      email: "",
      budget: "",
      time: "",
      department: "",
      reason: ""
    });
  };

  //////////////////////
  // USER REQUEST FORM
  /////////////////////

  return (
    <Container>
      <SpaceBetween size="xl">
        <SpaceBetween size="m">
            <FormField
                description="Department (DevOps, Data, MSP, Pre-sales)"
                label="Enter the sandbox account request details:"
            >
                <Input 
                  value={value.department} onChange={({ detail }) => updateFormValue({ department: detail.value})}
                  placeholder=""
                />
            </FormField>
            <FormField
                type="email"
                description="Email address"
                label=""
            >
                <Input 
                  value={value.email} onChange={({ detail }) => updateFormValue({ email: detail.value})} 
                  placeholder=""
                />
            </FormField>
            <FormField
                description="Required budget in USD"
                label=""
            >
                <Input 
                  value={value.budget} onChange={({ detail }) => updateFormValue({ budget: detail.value})} 
                  placeholder=""
                />
            </FormField>
            <FormField
                description="Requird session time (e.g. 1d 3h)"
                label=""
            >
                <Input 
                  value={value.time} onChange={({ detail }) => updateFormValue({ time: detail.value})} 
                  placeholder=""
                />
            </FormField>
            <FormField
                description="More details about the sandbox account request (Project or POC type)"
                label=""
            >
                <Input 
                  value={value.reason} onChange={({ detail }) => updateFormValue({ reason: detail.value})} 
                  placeholder=""
                />
            </FormField>
            <Button variant="primary" onClick={submit} loading={Event.status === "loading"}>
                Submit request
            </Button>
        </SpaceBetween>
      </SpaceBetween>
  </Container>
  );
};

//////////////////
// APP + CONTENT
//////////////////

const OverviewUsers = () => {
    const [splitPanelSize, setSplitPanelSize] = useState(500);
    const [splitPanelOpen, setSplitPanelOpen] = useState(false);
    const [hasManuallyClosedOnce, setHasManuallyClosedOnce] = useState(false);
    const hasSelection = useSelector((state) => state.selection.status === "selected");
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: "notification/dismiss" });
        dispatch({ type: "selection/dismiss" });
        dispatch({ type: "modal/dismiss" });
    }, [dispatch]);

    useEffect(() => {
        if (hasSelection && !hasManuallyClosedOnce) {
            setSplitPanelOpen(true);
        }
    }, [hasSelection, hasManuallyClosedOnce]);

    const onSplitPanelResize = ({ detail: { size } }) => {
        setSplitPanelSize(size);
    };

    const onSplitPanelToggle = ({ detail: { open } }) => {
        setSplitPanelOpen(open);

        if (!open) {
            setHasManuallyClosedOnce(true);
        }
    };

    return (
        <AppLayout
            navigation={<Navigation />}
            breadcrumbs={
                <BreadcrumbGroup
                    items={[
                        { text: "Admin", href: "#" },
                        { text: "Users", href: "#/users" }
                    ]}
                />
            }
            contentType="default"
            tools={<SideHelp />}
            onSplitPanelToggle={onSplitPanelToggle}
            splitPanelOpen={splitPanelOpen}
            splitPanelSize={splitPanelSize}
            onSplitPanelResize={onSplitPanelResize}
            stickyNotifications
            notifications={<NotificationFlashbar />}
            content={<UserRequestForm />}
        />
    );
};

const SideHelp = () => (
    <HelpPanel header={<h2>Sandbox Accounts for Events</h2>} className="test-class">
        <Box>
            <Header variant="h3">User List</Header>
            <SpaceBetween size="m">
                <Box>Sandbox Accounts for Events has three types of users:</Box>
                <Box>
                    <strong>Operator</strong>
                    <br />
                    Operators manages events and the according AWS account leases. The operator can also log into any
                    leased AWS account, e.g. for troubleshooting.
                </Box>
                <Box>
                    <strong>Admin</strong>
                    <br />
                    Admins can perform any tasks that operators can perform. Additionally, admins manage the underlying
                    AWS account pool, user lists and status tables.
                </Box>
                <Box>
                    <strong>End user</strong>
                    <br />
                    An end user can lease ang into a given AWS account via an event ID or lease ID.
                </Box>
            </SpaceBetween>
        </Box>
        <hr />
        <Box>
            <Header variant="h3">User Status</Header>
            <SpaceBetween size="m">
                <Box>
                    <strong>CONFIRMED</strong>
                    <br />
                    User account is created and validated. The user can log in via email + password.
                </Box>
                <Box>
                    <strong>UNCONFIRMED</strong>
                    <br />
                    User account is created, but is waiting for the email verification. The user has received an email
                    with a verification code that needs to be entered to validate the user account.
                </Box>
                <Box>
                    <strong>FORCE_CHANGE_PASSWORD</strong>
                    <br />
                    User is created and validated. The user has received an email with the initial login password. On
                    first login, the user needs to change this password.
                </Box>
            </SpaceBetween>
        </Box>
        <hr />
        <Box>
            <Header variant="h3">Limitations</Header>
            <ul>
                <li>You cannot change a user's email address once created.</li>
                <li>You cannot delete your own user account. Contact another admin to delete your user account.</li>
                <li>
                    You cannot revoke admin rights from your own user account. Contact another admin to revoke admin
                    rights from your account.
                </li>
            </ul>
        </Box>
        <hr />
        <GitHubLinks/>
    </HelpPanel>
);

export default OverviewUsers;
